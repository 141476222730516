import ClientDetails from "../../assets/utils/images/navBarIcon/ClientDetails.svg";
import AddClient from "../../assets/utils/images/navBarIcon/AddClient.svg";
import AddAdmin from "../../assets/utils/images/navBarIcon/AddAdmin.svg";
import ViewAllInterviews from "../../assets/utils/images/navBarIcon/ViewAllInterviews.svg";
import DateWiseInterview from "../../assets/utils/images/navBarIcon/DateWiseInterview.svg";
import ViewAllPositions from "../../assets/utils/images/navBarIcon/ViewAllPositions.svg";
import CreateInterviews from "../../assets/utils/images/navBarIcon/CreateInterviews.svg";
import InterviewHistory from "../../assets/utils/images/navBarIcon/InterviewHistory.svg";
import InterviewerDetails from "../../assets/utils/images/navBarIcon/InterviewerDetails.svg";
import AddInterviewer from "../../assets/utils/images/navBarIcon/AddInterviewer.svg";
import AvailabilitySlots from "../../assets/utils/images/navBarIcon/AvailabilitySlots.svg";
import viewRolesImg from "../../assets/utils/images/navBarIcon/ViewRolesCategories.svg";
import audit from "../../assets/utils/images/navBarIcon/audit.svg";
import AllGuidesImg from "../../assets/utils/images/navBarIcon/AllGuides.svg";
import QuestionBankImg from "../../assets/utils/images/navBarIcon/QuestionBank.svg";
import GuideTagsImg from "../../assets/utils/images/navBarIcon/GuideTags.svg";

export const Client = [
    {
        icon: ClientDetails,
        label: 'Client Details',
        to: '/client/view',
    },
];

export const Position = [
    {
        icon: 'pe-7s-eyedropper',
        label: 'Add Position',
        to: '#/position/add',
    }
];

export const Auth = [
    {
        icon: 'pe-7s-display2',
        label: 'Signin',
        to: '#/auth/login',
    },
    {
        icon: 'pe-7s-eyedropper',
        label: 'Signup',
        to: '#/auth/register',
    },
];


export const Interviews = [
    {
        icon: ViewAllInterviews,
        label: 'View All Interviews',
        to: '/interviews/all',
    },
    {
        icon: DateWiseInterview,
        label: 'Date Wise Interviews',
        to: '/interviews/today',
    },
    {
        icon: ViewAllPositions,
        label: 'View All Positions',
        to: '/interviews/positions',
    },
    {
        icon: CreateInterviews,
        label: 'Create Interview',
        to: '/application'
    },
    {
        icon: viewRolesImg,
        label: ' View All Roles & Categories',
        to: '/interviews/roles',
    },
    {
      icon:audit,
      label:"View All Audits",
      to: '/interviews/audit'
    },
    {
        icon:ViewAllInterviews,
        label:"Client Internal Interviews",
        to: '/interviews/internal-interviews'
      },
      {
        icon:ViewAllInterviews,
        label:"View Demo Interviews",
        to: '/interviews/demo-interviews'
      },
];

export const History = [
    {
        icon: InterviewHistory,
        label: 'Interview History',
        to: '/history',
    },
];

export const Guide = [
    {
        icon: AllGuidesImg,
        label: 'All Guides',
        to: '/interview-guides',
    }, 
    {
        icon: AllGuidesImg,
        label: 'All QuickScreens',
        to: '/quickscreen/',
    },
];

export const Tags = [
    {
        icon: GuideTagsImg,
        label: 'Guide Tags',
        to: '/guide-tags',
    },
]

export const QuestionBank = [
    {
        icon: QuestionBankImg,
        label: 'Guides Questions',
        to: '/question-bank',
    },
    {
        icon: QuestionBankImg,
        label: 'Interview Guide Templates',
        to: '/guide-templates',
    },
    {
        icon: QuestionBankImg,
        label: 'QuickScreen Skills',
        to: '/skills',
    },
    {
        icon: QuestionBankImg,
        label: 'QuickScreen Questions',
        to: '/quickscreen-question-bank',
    },
];

export const Interviewer = [
    {
        icon: InterviewerDetails,
        label: 'Interviewer Details',
        to: '/interviewer/view',
    },

    {
        icon: AddInterviewer,
        label: 'Add Interviewer',
        to: '/interviewer/create',
    },
    {
      icon: AvailabilitySlots,
      label: 'Availability Slots',
      to: '/interviewer/availability-slots',
     }
];

export const Admin = [
  {
    icon: AddAdmin,
    label: 'Add an admin',
    to: '/admin/add',
  },
  {
    icon: AddAdmin,
    label: 'Admin Details',
    to: '/admin/admin-details',
  }
];

